@import "../../variables.scss";

.cigar-page {
  color: $primary-black;
  &__pill {
    display: flex;
    background-color: $primary-white;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
  }
  &__brand {
    color: $secondary-grey;
    font-weight: 700;
    font-size: 1rem;
  }
  &__cigar-name {
    font-weight: 700;
    font-size: 1.5rem;
  }
  &__details {
    font-weight: 600;
    font-size: 0.75rem;
    span {
      font-weight: 300;
      margin-right: 0.5rem;
    }
  }
  &__large-text {
    font-weight: 300;
    font-size: 0.875rem;
  }
  &__section-title {
    font-size: 1rem;
    font-weight: 700;
  }
  &__box {
    width: 46%;
    justify-content: space-between;
    display: block;
    background-color: $tertiary-grey;
    padding: 1rem;
    border-radius: 7px;
    border: 3px solid rgba(255, 255, 255, 0.4000000059604645);
    font-size: 0.875rem;
    font-weight: 600;
    span {
      display: block;
      font-weight: 300;
      font-size: 0.75rem;
    }
  }
  &__video {
    iframe {
      width: 100%;
      height: 12rem;
      border-radius: 0.875rem;
    }
  }
}
