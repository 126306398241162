@import "./variables.scss";

.App {
  &__background {
    background-color: $primary-grey;
    height: 100%;
    width: 100vw;
    padding-bottom: 3rem;
  }
}
